import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// import pdf1 from 'assests/Pdf/CCA/Association_Activity_2023_24.pdf';

// import pdf2 from 'assests/Pdf/CCA/INTER_SCHOOL_WINNERS_2024_25.pdf';


import Main from 'layouts/Main';

import Container from 'components/Container';
import { Button } from '@mui/material';




const ACHCOS2024 = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const pdf2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/pdf/CCA/INTER_SCHOOL_PPT_2024-2025.pdf`;
  
  const theme = useTheme();

  const mock = [

    {
      name: '',
      title: 'INTER SCHOOL COMPETITION WINNERS 2024-25',
     
      href:pdf2,
    }
  ];

  return (
    // <Main>
    <Container>
      
      <Box>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
          Co-Scholastic 2024-25
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                width={0.9}
                height={0.9}
                component={Card}
                boxShadow={0}
                variant={'outlined'}
                bgcolor={'alternate.main'}
              >
                <CardContent sx={{ padding: 3 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                  
                    <Button 
                    href={item.href} target="_blank">
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                    </Button>
                  </ListItem>
                </CardContent>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      
    </Container>
    // </Main>
  );
};

export default ACHCOS2024;
