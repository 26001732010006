import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const Avidreader2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Avidreader/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Avidreader/2.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 2,
        },


    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                    AVID READERS
                                </Typography>
                                {/* <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 1 to 8  &  Date: 7 and 29 August 2024,
                                </Typography> */}
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “The more you read ,the more things you will know. The more you learn the more places you will go.”
                                    <br />
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Department of Library, National Public School, Yeshwanthpur recognises students as AVID READERS,
                                    acknowledging their love for reading and inspiring others to follow their footsteps. The students were
                                    assessed based on their skills, level of interest in reading, level of comprehension, summarisation of the
                                    plot, participation in reading activities and also maintenance of decorum in the library. The primary aim
                                    for students in reading is to develop critical thinking and analytical skills thereby enhancing academic
                                    success and laying the foundation for future career advancement.
                                    <br></br>
                                    On 16 December 2024 and 17 December 2024, the students of Classes 1 to 8 were conferred with a badge
                                    as an acknowledgement for their reading skills. Vice Principal, Ms Jeshma congratulated the students of
                                    Classes 1 to 5 for their active interests in reading books and encouraged other students to acquire AVID
                                    READER badges in the future. Principal, Ms Sheeja Manoj felicitated the students of Classes 6 to 8 for their
                                    true interest in reading and also advised other students to limit their time in gadgets and focus more in
                                    developing the habit of reading.
                                    <br></br>

                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “The more you read the more things you will know. The more you learn the more places you will go.”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>


                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        {/* <Box marginBottom={4}>
                                    <Archive2023/>
                        </Box> */}
                        <Box marginBottom={4}>
                            <LiterarySidebar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Avidreader2024;