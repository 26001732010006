import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../MathClub2023/components/Sidebar2024';
import Archive2022 from 'views/MathClub/components/Archive2022';


const Mathcca6to82024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/MathClub/Mathcca6to8/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/MathClub/Mathcca6to8/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/MathClub/Mathcca6to8/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/MathClub/Mathcca6to8/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/MathClub/Mathcca6to8/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/MathClub/Mathcca6to8/6.webp`;


    const photos = [
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Mirror Math
                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                    Class 3: Character creation using tangrams (Story telling)
                                    <br></br>
                                    Class 4: Character creation using tangrams (Role Play: Healthy and unhealthy)
                                    <br></br>
                                    Class 5: Character creation using tangrams (Puppet Show)
                                </Typography> */}
                                {/* <Typography
                                    variant={'h5'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “The more you push yourself in extracurricular endeavors, the more you'll develop these skills”
                                    <br />
                                </Typography> */}

                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 6 to 8   Date: 22 November 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Mirror Math Activity was conducted for the students of classes 6, 7 and 8 to enhance
                                    their analytical skills and creativity. Students explored concepts like the Four Colour
                                    Theorem, Congruency, Area and Symmetry which are highly engaging aspects of geometry.
                                    <br></br>
                                    In the preliminary round, they created intricate patterns based on symmetry, showcasing
                                    their understanding of balance and aesthetics. The final round introduced advanced
                                    challenges such as calculating areas, ensuring congruency and reflecting designs under
                                    specific constraints, pushing their problem-solving abilities further. Students of Class 8 were
                                    given the additional and exciting task to mirror the images. The activity improved their
                                    spatial reasoning, logical thinking and precision. It also fostered teamwork and a deeper
                                    appreciation for mathematical applications.
                                    <br></br>
                                    Students demonstrated exceptional creativity while adhering to mathematical principles,
                                    making the event a perfect blend of innovation and discipline. The Mirror Math Activity was
                                    a resounding success, inspiring students to see Mathematics as a tool for creative
                                    exploration and practical problem-solving.
                                    <br></br>
                                </Typography>
                            </Box>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Mathcca6to82024;