import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Agaman2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/17.webp`;
    const p18 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/18.webp`;
    const p19 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/19.webp`;
    const p20 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/20.webp`;
    const p21 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/21.webp`;
    const p22 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/22.webp`;
    const p23 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/23.webp`;
    const p24 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/24.webp`;
    const p25 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/25.webp`;
    const p26 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/26.webp`;
    const p27 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/27.webp`;
    const p28 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/28.webp`;
    const p29 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/29.webp`;
    const p30 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/30.webp`;
    const p31 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/31.webp`;
    const p32 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/32.webp`;
    const p33 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/33.webp`;
    const p34 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/34.webp`;
    const p35 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/35.webp`;
    const p36 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/36.webp`;
    const p37 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/37.webp`;
    const p38 = `${config.base_image_url}/home/events-activities/school-programs/Agaman/38.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p37,
            source: p37,
            rows: 1,
            cols: 1,
        },
        {
            src: p38,
            source: p38,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
        },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },
        {
            src: p19,
            source: p19,
            rows: 1,
            cols: 1,
        },
        {
            src: p20,
            source: p20,
            rows: 1,
            cols: 1,
        },
        {
            src: p21,
            source: p21,
            rows: 1,
            cols: 1,
        },
        {
            src: p22,
            source: p22,
            rows: 1,
            cols: 1,
        },
        {
            src: p23,
            source: p23,
            rows: 1,
            cols: 1,
        },
        {
            src: p24,
            source: p24,
            rows: 1,
            cols: 1,
        },
        {
            src: p25,
            source: p25,
            rows: 1,
            cols: 1,
        },
        {
            src: p26,
            source: p26,
            rows: 1,
            cols: 1,
        },
        {
            src: p27,
            source: p27,
            rows: 1,
            cols: 1,
        },
        {
            src: p28,
            source: p28,
            rows: 1,
            cols: 1,
        },
        {
            src: p29,
            source: p29,
            rows: 1,
            cols: 1,
        },
        {
            src: p30,
            source: p30,
            rows: 1,
            cols: 1,
        },
        {
            src: p31,
            source: p31,
            rows: 1,
            cols: 1,
        },
        {
            src: p32,
            source: p32,
            rows: 1,
            cols: 1,
        },
        {
            src: p33,
            source: p33,
            rows: 1,
            cols: 1,
        },
        {
            src: p34,
            source: p34,
            rows: 1,
            cols: 1,
        },
        {
            src: p35,
            source: p35,
            rows: 1,
            cols: 1,
        },
        {
            src: p36,
            source: p36,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    AAGAMAN-2024
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Date: Friday, 29 November 2024
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    ‘A Celebration of Art, Culture and Talent’
                                    <br />

                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur, organised its triennial cultural event, Aagaman 2024, on Friday, 29 November 2024 in the school premises. The event brought together students, teachers and parents for a vibrant celebration that showcased the creativity, diversity and unity of the school community, leaving everyone with unforgettable memories to cherish for years to come.
                                    <br></br>
                                    The event was graced by several luminaries, including the Chief Guest, Ms Padmavati Rao, a celebrated personality in the fields of film, arts, culture and literature. Other distinguished guests included revered Chairman Dr Shivshankar, respected Director of I-5 Academy Dr Manjula Shivshankar, Principal Ms Sheeja Manoj and cherished members of the management team.
                                    <br></br>
                                    <div> A Grand Opening</div>
                                    Traditionally, lamp-lighting marks the onset of knowledge and enlightenment and started off the evening by offering a soulful invocation song. The inauguration dance has been an enthralling experience with its sheer blend of classical Bharatanatyam and Kathak to be a deep revelation before the audience. Adding another dimension to it all are the lyrics that was rendered soulfully by the school choir.
                                    In her speech, Chief Guest Ms Padmavati Rao emphasized humility and narrated a sensitive story of Shwetha Ketu which inspired every soul. Principal Ms Sheeja Manoj appreciated the commitment of the children and the faculty and took this opportunity to thank their parents for the relentless encouragement.
                                    <br></br>
                                    <div> Honouring Excellence and Commitment</div>
                                    Aagaman 2024 also served as a platform to honour two pillars of the school’s strength, its dedicated educators and its high-achieving students. Teachers who had completed a decade of service were felicitated, recognizing their unwavering commitment to education. Meanwhile, a total of 37 students who excelled in their 10th and 12th-grade board examinations, scoring above 95%, were celebrated for their exceptional academic achievements.
                                    <br></br>
                                    <div> Cultural Extravaganza:</div>
                                    Around the World in 80 Minutes
                                    The cultural programme, ‘Around the World in 80 Minutes’, was the highlight of the evening. This innovative performance took the audience on a global journey, skillfully narrated by student comperes dressed as literary characters Phileas Fogg and Captain Nemo from Jules Verne's classics.
                                    <br></br>
                                    <div>The journey went through the cultural essence of six continents, each represented by a natural element:</div>
                                    <div>• Africa and Europe: Represent Earth, the region's history depicted through stunning dances.</div>
                                    <div> • Asia: Represent Fire, energetic and colourful performances.</div>
                                    <div>• Oceania: Represent Water, with fluid and calm movements.</div>
                                    <div>• South America: Represent Wind, celebrated with rhythmic and lively dances.</div>
                                    <div>• North America: Represent Sky, highlighted with a bright and futuristic flair.</div>
                                    <br></br>
                                    Each continent was brought to life through three distinct performances, weaving a vivid tapestry of global cultural expressions. The spectacle culminated in a grand parade of students dressed in continent-specific costumes, followed by a live rendition of the song One World, performed by the student body.
                                    <br></br>
                                    The event's finale was a breath taking live music performance by the school's extremely talented band, who’s perfect synchronization added brilliance to the celebration. The meticulous choreography, breath taking backdrops, and vibrant costumes reflected the hard work and creativity of the students and staff.
                                    <br></br>
                                    <div>A Cherished Memory</div>
                                    Aagaman 2024 was more than just a cultural event. It was a celebration of vision, creativity and collective effort. With over 1,200 students participating, it provided a platform for each child to explore and express their talents. The evening was a resounding success, leaving the audience spellbound and deeply appreciative of the school's innovative approach to holistic education.
                                    <br></br>

                                </Typography>

                            </Box>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Agaman blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Agaman2024;