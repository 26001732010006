import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../SEWA/components/Sidebar2024';
import { Archive2022 } from '../ScintillaOfArt/components';

const Sramdhan2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/sewa/Sramdhan/12.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 1.5,
        },
        {
            src: p7,
            source: p7,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p8,
            source: p8,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p9,
            source: p9,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p10,
            source: p10,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p11,
            source: p11,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p12,
            source: p12,
            rows: 1.5,
            cols: 2,
        },

    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center' >
                                    <div>SRAMDHAN</div>
                                    <div>A Heartfelt Gesture of Gratitude by Grade 12 Students</div>
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class 12
                                    Date:21 December 2024
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    ‘We must find time to stop and thank the people who make a difference in our lives.’<br />
                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                In a heartfelt gesture of appreciation, the students of Class 12 at National Public School Yeshwanthpur organized a special event called ‘Sramdhan’ to honour and thank the auxiliary staff for their dedicated service to the school. This initiative was aimed at recognising the hard work of the non-teaching staff and to make them feel valued. The day was filled with warmth, gratitude and sincere gestures as part of SEWA (Social Empowerment through Work Education and Action).
                                    <br></br>
                                    The students demonstrated remarkable initiative and pooled their resources to provide a special lunch for the staff. The menu of the meal was thoughtfully selected, with unlimited quantity and catered to the diverse tastes of the staff members. On the day of the event, the students personally served the lunch to the auxiliary staff, ensuring they felt appreciated and recognised. This gesture was a direct reflection of the students’ gratitude for their contributions. To enhance the occasion, the students maintained high hygiene standards, decorated the school corridor with traditional rangoli and expressed their thanks through heartfelt speeches. The students’ live music band performed a mix of Kannada and Hindi songs creating a warm and festive atmosphere.
                                    <br></br>
                                    The event was executed with great attention to detail, showcasing the students' enthusiasm and dedication in every aspect. The vision of the Principal Ms Sheeja Manoj of instilling a sense of gratitude among the younger generation was notably achieved, as the students demonstrated a strong sense of responsibility and leadership. ‘Sramdhan’ was more than just a simple act of service; it was a powerful expression of gratitude and a reminder of the importance of recognising and appreciating those who work tirelessly behind the scenes.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Gratitude makes sense of our past, brings peace for today and creates a vision for tomorrow."
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Sramdhan2024;