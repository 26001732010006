import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Elevate2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/13.webp`;
    const p14 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/14.webp`;
    const p15 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/15.webp`;
    const p16 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/16.webp`;
    const p17 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/17.webp`;
    const p18 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/18.webp`;
    const p19 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/19.webp`;
    const p20 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/20.webp`;
    const p21 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/21.webp`;
    const p22 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/22.webp`;
    const p23 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/23.webp`;
    const p24 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/24.webp`;
    const p25 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/25.webp`;
    const p26 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/26.webp`;
    const p27 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/27.webp`;
    const p28 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/28.webp`;
    const p29 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/29.webp`;
    const p30 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/30.webp`;
    const p31 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/31.webp`;
    const p32 = `${config.base_image_url}/home/events-activities/school-programs/Elevate/32.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
        {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
        },
        {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
        },
        {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
        },
        {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
        },
        {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
        },
        {
            src: p19,
            source: p19,
            rows: 1,
            cols: 1,
        },
        {
            src: p20,
            source: p20,
            rows: 1,
            cols: 1,
        },
        {
            src: p21,
            source: p21,
            rows: 1,
            cols: 1,
        },
        {
            src: p22,
            source: p22,
            rows: 1,
            cols: 1,
        },
        {
            src: p23,
            source: p23,
            rows: 1,
            cols: 1,
        },
        {
            src: p24,
            source: p24,
            rows: 1,
            cols: 1,
        },
        {
            src: p25,
            source: p25,
            rows: 1,
            cols: 1,
        },
        {
            src: p26,
            source: p26,
            rows: 1,
            cols: 1,
        },
        {
            src: p27,
            source: p27,
            rows: 1,
            cols: 1,
        },
        {
            src: p28,
            source: p28,
            rows: 1,
            cols: 1,
        },
        {
            src: p29,
            source: p29,
            rows: 1,
            cols: 1,
        },
        {
            src: p30,
            source: p30,
            rows: 1,
            cols: 1,
        },
        {
            src: p31,
            source: p31,
            rows: 1,
            cols: 1,
        },
        {
            src: p32,
            source: p32,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    ELEVATE 2024
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Date: 12 December 2024
                                </Typography>
                               
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    On Thursday, 12 December 2024, National Public School, Yeshwanthpur hosted ELEVATE 2024, a vibrant sports meet that brought together students from 12 school, filled with spirited competition and unity. The event featured basketball, chess, table tennis and volleyball offering students a platform to showcase their athletic and intellectual talents.
                                    <br></br>
                                    A spectacular march past, where contingents showcased their impeccable discipline, but also their strength, pride, enthusiasm and school spirit. It provided a platform for individuals to showcase their talents, challenge their limits and experience the thrill of competition.
                                    <br></br>
                                    Beyond physical prowess, it encouraged a spirit of unity and friendship, bringing participants together from diverse backgrounds. The event also instilled essential life skills such as time management, focus and perseverance, making it an important occasion for personal growth and community bonding.
                                    <br></br>
                                    Referees who played at the National Level and qualified sports personalities from the Association and Federation officiated a crucial role in maintaining fairness, order and integrity during ELEVATE 2024.
                                    <br></br>
                                    ELEVATE 2024 not only showcased the sporting prowess and strategic thinking of NPS YPR students but also emphasised teamwork, discipline and sportsmanship. The enthusiasm of both participants and spectators created a lively atmosphere, making the event memorable. National Public School, Yeshwanthpur looks forward to future events that will continue to foster excellence in sports and leadership.
                                    <br></br>

                                </Typography>

                            </Box>
                            <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    A sports meet is not just about competition; it’s about building bonds, embracing challenges, and discovering the strength within.
                                    <br />

                                </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Elevate blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Elevate2024;