import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly7B2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/7B/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/7B/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/7B/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/7B/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.3,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },

        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    "Dreamers toDoers: India's Legacy"
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 7B              Date: 2, 3 and 4 January 2025

                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Imagination is the beginning of creation. You imagine what you desire, you will what you imagine and at last,
   you create what you will"- George Bernard Shaw

<br/>
        </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The students of class 7B presented a captivating and inspiring class assembly on the topic "Dreamers to
                                    Doers: India's Legacy", to celebrate India&#39;s remarkable accomplishments in the fields of Science,
                                    Technology, Business and Sports.
                                    <br />
                                    <br />
                                    The skit highlighted the contribution of India&#39;s pioneer figures, like Sir M. Visvesvaraya, Srinivasa
                                    Ramanujan and C.V. Raman, alongside modern business leaders like Ratan Tata, Dhirubhai Ambani and
                                    Kiran Mazumdar-Shaw. Their achievements in Engineering, Mathematics, Business and Biotechnology
                                    inspired students with lessons in resilience, vision and leadership.
                                    <br />
                                    <br />
                                    The assembly featured a lively dance symbolizing India&#39;s growth and a quiz on prominent figures and
                                    their achievements. It emphasised India&#39;s legacy built on visionary dreams, encouraging students to
                                    believe in their potential. The event inspired them to pursue their goals with passion, determination
                                    and innovation for remarkable success.
                                    <br />
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “The best way to predict the future is to create it.”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly7B2024;