import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const ChristmasCelebration2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Christmas/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Christmas/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Christmas/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Christmas/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },

        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CHRISTMAS CELEBRATION 2024-25
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes : Preprimary to Class 8          Date: 20 December 2024s
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “A joy that is shared is the joy that is doubled” Christmas, a festival of joy brings with itself the
                                    special message of hope, happiness and joy”<br />
                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The students of Pre- Primary and Classes 1-8 presented a special assembly on the theme
                                    “Christmas” on Friday, 20 December 2024.<br></br>
                                    The assembly began with a significant message on
                                    Christmas which is all about sharing and being generous. As a continuation to the celebrations, the
                                    students presented melodious Christmas carols followed by a beautiful dance performance. The
                                    children were filled with joy and exhilaration as Santa Claus mingled with the audience and amused
                                    with his charm by dancing to the tune of Jingle Bells.
                                    <br></br>
                                    It really made the ambience pleasant and exuberant. The sound of carols, the joy in the atmosphere
                                    filled up the surroundings and made the Christmas assembly more effervescent. This was the best
                                    way to end the year and welcome another one with outstretched arms.
                                    <br></br>

                                </Typography>

                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "CHRISTMAS A FESTIVAL OF JOY, HOPE AND HAPPINESS"
                                <br />

                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Christmas blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ChristmasCelebration2024;