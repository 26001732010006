import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import Sidebard2024 from '../HealthAndWellnesClub/Sidebard2024';
import Archive2023 from '../HealthAndWellnesClub/Archive2023';

const Bullying2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Bullying/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Bullying/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Bullying/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Bullying/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Bullying/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Bullying/6.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p5,
            source: p5,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p6,
            source: p6,
            rows: 1.5,
            cols: 1.5,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    WORKSHOP ON BULLYING
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    CLASS : 9  Date: 10 January 2025
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    On 10 January, 2025, National Public School, Yeshwanthpur organised a workshop on the topic
                                    ‘Bully to Buddy’ for the students of class 9. The workshop was led by Dr Linu Sebastian.
                                    <br></br>
                                    The session began by sensitising the students to various forms of bullying. The students were
                                    made to understand who is a bully and how to tackle a bully individually and collectively? They
                                    were informed about kinds of bullying and solutions to the problems of bullying. The students
                                    were encouraged to take proactive measures against bullying and seek support when needed.
                                    <br></br>
                                    The workshop concluded with an interactive question and answer session. We at NPS, are
                                    committed to provide a safe and supportive environment to all its students. Workshop like these
                                    are school’s broader initiative to ensure that students are well informed about such challenges
                                    and are equipped to handle them responsibly.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h4'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                <div>Strong people stand up for themselves, but stronger people stand up for others.</div>
                                <div>Chris Gardner</div>
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebard2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2023 />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Bullying2024;