import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItemText from '@mui/material/ListItemText';
import CardMedia from '@mui/material/CardMedia';
import ImageList from '@mui/material/ImageList';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';

import Container from 'components/Container';


const Aluminimeet2024 = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/1.webp`;
  const p2 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/2.webp`;
  const p3 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/3.webp`;
  const p4 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/4.webp`;
  const p5 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/5.webp`;
  const p6 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/6.webp`;
  const p7 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/7.webp`;
  const p8 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/8.webp`;
  const p9 = `${config.base_image_url}/home/home-static/static-images/all-static-images/alumini/24-25/9.webp`;

  
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1.5,
      cols: 1,
    },
    {
          src: p2,
          source:p2,
          rows: 2,
          cols: 1,
        },
    {
          src: p3,
          source: p3,
          rows: 1,
          cols: 1,
      },  
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    },
    {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
    },
    {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
    },
    {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
    },

      ];

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          {/* <Typography
            sx={{
              textTransform: 'uppercase',
            }}
            gutterBottom
            color={'text.secondary'}
            align={'center'}
            fontWeight={700}
          >
            ACHIEVING MILESTONES
          </Typography> */}
          <Typography fontWeight={700} variant={'h4'} align={'center'}>
          ALUMNI MEET 2024-25 
          </Typography>
          <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School, Yeshwanthpur organised the first Alumni Meet - CONVERGENCE on 19 December 2024. It was a day filled with excitement, emotions and nostalgia as the alumni came together to relive their school days and connect with their old classmates and teachers. 
        <br/>
        The meet was attended by around 70 alumni from all over the country, who graduated a few years ago. The school principal, Ms. Sheeja Manoj, accorded a warm welcome to all and apprised the alumni about the school’s changing infrastructure and various new strides, launches, initiatives taken in recent times for the holistic development of students. 
        <br/>
        The event infused a concoction of emotions as the alumni experienced a wave of nostalgia and camaraderie. The first Alumni Meet of NPSYPR was a grand success, as it provided the alumni a chance to reconnect with their alma mater and with each other. The event was not only a celebration of the school's legacy, but it was also an opportunity for the alumni to network and to give back to the school in various ways. 
        <br></br>
          <div>
          </div>
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        "The Alumni Meet: a celebration of our past and a toast to our future."
        <br/>
        </Typography>
        {/* <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              <Card
                sx={{
                  boxShadow: 0,
                  background: 'transparent',
                  backgroundImage: 'none',
                }}
              >
                <Box
                  component={CardMedia}
                  borderRadius={2}
                  width={1}
                  height={1}
                  minHeight={320}
                  image={item.avatar}
                />
                <Box
                  component={CardContent}
                  bgcolor={'transparent'}
                  marginTop={-5}
                >
                  <Box component={Card}>
                    <CardContent>
                      <ListItemText
                        primary={item.name}
                        secondary={item.title}
                      />
                    </CardContent>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid> */}
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    {/* </Box> */}
      </Box>
    </Container>
  );
};

export default Aluminimeet2024;