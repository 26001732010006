import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Archive2022 from 'views/CyberClub/components/Archive2022';
import Sidebar2024 from '../LibraryManagement2023/component/Sidebar2024';


const CyberAssociationSenior2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/Cyber/CyberAssociation9-12/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/Cyber/CyberAssociation9-12/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/Cyber/CyberAssociation9-12/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/Cyber/CyberAssociation9-12/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/Cyber/CyberAssociation9-12/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/Cyber/CyberAssociation9-12/6.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 3,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 3,
        },
        {
            src: p3,
            source: p3,
            rows: 2,
            cols: 3,
        },
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 3,
        },
        {
            src: p5,
            source: p5,
            rows: 2,
            cols: 3,
        },
        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 3,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    MISE-EN-SCENE
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 to 12  Date: 6 December 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    Senior Cyber Association organized MISE-EN-SCENE an inter house competition  on Tuesday, 3 December 2024 aimed at fostering technical skills and teamwork among students. The event centered around video editing, where participants learned essential techniques such as cutting, transitions and sound mixing.
                                    <br></br>
                                    The event was a group project where participants worked in teams to create short films based on pre-assigned themes. This exercise encouraged students to work in teams brainstorm, allocate tasks effectively and manage their time efficiently.
                                    <br></br>
                                    It also reinforced the importance of communication and adaptability, as team members worked through challenges to produce cohesive projects. In the end, students showcased their short films, which reflected both creativity and technical learning.
                                    <br></br>
                                    The event not only enhanced their video editing abilities but also instilled valuable teamwork and problem-solving skills, making it an enriching experience for all involved by showing  their creativity and passion on display.
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h5'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Movies are time machines that let us travel to places we've never been and feel things we've never imagined."
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={3}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>

                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default CyberAssociationSenior2024;