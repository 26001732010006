import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly7A2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/7A/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/7A/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/7A/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/7A/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/class-assembly/7A/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/class-assembly/7A/6.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.3,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },

        {
            src: p4,
            source: p4,
            rows: 1.3,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
          },
          {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
          },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Christmas Magic
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    CLASS: 7A               DATE: 14, 17 and 18 December 2024

                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Imagination is the beginning of creation. You imagine what you desire, you will what you imagine and at last,
   you create what you will"- George Bernard Shaw

<br/>
        </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The class assembly held over three vibrant days was a delightful blend of learning, creativity and festive cheer.
                                    The assembly began with a brief introduction about the festival of joy ‘Christmas’ and its significance. Students
                                    mesmerised the audience with a heartfelt rendition of classic Christmas carols which set the tone for the
                                    joyous season ahead.
                                    <br />
                                    On day 2 students presented an insightful and engaging skit that explored the fascinating evolution of Santa
                                    Claus through the lens of coca-cola’s influence. The creative portrayal shed light on how cultural elements
                                    shaped Santa’s iconic red suit and cheerful persona. It left the audience enthralled and spellbound. On the
                                    final day, an exciting and interactive quiz was conducted on Christmas traditions and history.
                                    <br />
                                    The Principal, commended the students for their enthusiasm, teamwork and appreciated their efforts to make
                                    the assembly a grand success. The vibrant performances and cheerful atmosphere ensured that all participants
                                    and audience thoroughly enjoyed the assembly.
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "sprinkle of history, a dash of music, and a whole lot of Christmas magic!"
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly7A2024;