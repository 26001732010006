import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Sansad2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Sansad/9.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Sansad 2024
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class:11D            Date: 17 December 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    In a riveting display of intellect and passion, National Public School, Yeshwanthpur, hosted a Mock Parliament on
                                    Tuesday,17 December 2024 that plunged headfirst into the complexities of the ongoing Manipur crisis. The event was,
                                    held in the school auditorium, transformed into a battleground of ideas as students donned the roles of
                                    Parliamentarians, debating one of the most contentious and emotionally charged issues facing the nation today.
                                    <br></br>
                                    The
                                    session began with the Speaker calling the house to order, setting the stage for an intense debate. The ruling party
                                    was on the receiving end of a fiery barrage of questions during Question Hour and the Zero Hour, as opposition MPs
                                    demanded accountability for the violence and unrest in Manipur. Accusations flew, emotions ran high, and the air
                                    buzzed with the fervour of youth determined to find solutions. The debate went full throttle as students took on
                                    issues of ethnic tensions, governance failures and the urgent need for humanitarian aid. Voices rose in unison,
                                    echoing the sentiments of a nation divided yet yearning for unity.
                                    <br></br>
                                    The house deliberated passionately before arriving at a rare consensus, a moment that symbolized hope amidst
                                    discord. The Mock Parliament was not mere academic exercise but a powerful reminder of the role youth can
                                    play in shaping the nation&#39;s future. Participants left the session with a deeper understanding of the crisis and a
                                    renewed commitment to fostering harmony in diversity. This event reaffirmed the commitment of National Public
                                    School, Yeshwanthpur in nurturing informed, empathetic, and socially conscious leaders.
                                    <br></br>

                                </Typography>

                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                A sports meet is not just about competition; it’s about building bonds, embracing challenges, and discovering the strength within.
                                <br />

                            </Typography>
 */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {/* <Box >
                                <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Where festivities and memories entwine—Sansad blossoms in vibrant hues!"
                                    <br />

                                </Typography>
                            </Box> */}
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Sansad2024;