import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const GraduationDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/GraduationDay/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/GraduationDay/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/GraduationDay/3.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 2,
            cols: 2,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                GRADUATION DAY 2024-25
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                Date: 17 January 2025
                                </Typography>
                                {/* <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “A joy that is shared is the joy that is doubled” GraduationDay, a festival of joy brings with itself the
                                    special message of hope, happiness and joy”<br />
                                </Typography> */}
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Graduation Day of Batch 2024-25 of National Public School, Yeshwanthpur, was
                                    celebrated on 17 January 2025 with great exhilaration and jubilation. The Graduation Day
                                    for the Class of 2024-25 began with a proud procession of 79 graduates in their
                                    ceremonial robes and caps, symbolizing the culmination of their hard work and
                                    perseverance of their school life and the start of the next phase of the exciting journey
                                    ahead.<br></br>
                                    The ceremony commenced with the traditional lighting of the lamp, by all the dignitaries
                                    followed by an invocation dance that set a spiritual and celebratory tone for the event. A
                                    key highlight of the day was the Convocation Address by Ms Salma Fahim IAS, secretary in
                                    the department of Tourism, Government of Karnataka. She highlighted five key principles
                                    for the graduates to reflect on: embrace change, pursue your passion, stay true to your
                                    values, be a lifelong learner, and give back to the community. The Principal, Ms Sheeja
                                    Manoj in her address implored the students to remember and to take along four magic
                                    words, humility, courage, integrity and compassion as they traverse in their journey
                                    ahead. The Principal then officially declared the students as graduates, marking the end of
                                    one chapter and the beginning of another, as the audience erupted in applause.
                                    <br></br>
                                    The ceremony featured emotional and humorous moments as the student's profiles were
                                    read out, highlighting their aspirations and triumphs. The profound reflections from both
                                    students and parents, sharing unique journeys, challenges overcome and the growth
                                    experienced at school, adding a personal and heartfelt touch to the occasion. It was
                                    followed by the final retreat of the Graduates, carrying the lamp of knowledge and the
                                    legacy of their alma mater, filled the hall with pride, signifying their transition from
                                    students to graduates.
                                    <br></br>
                                    The ceremony concluded with a celebratory dinner, where students, parents and faculty
                                    gathered to reflect on the memorable day and share in the joy of the graduates’ success.
                                    The atmosphere was filled with pride and excitement, as the Class of 2024 took one last
                                    opportunity to bond with their peers and teachers before moving forward into the future.
                                    It was a day of recognition, inspiration and celebration, as the graduates prepared to
                                    embark on new journeys.
                                    <br></br>

                                </Typography>

                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                ‘Go confidently in the direction of your dreams. Live the life you have imagined.’ – Henry Thoreau
                                <br />

                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default GraduationDay2024;